import React, { useState, useEffect, useRef } from 'react';
import './DashboardMainR.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay as fasFaPlay } from '@fortawesome/free-solid-svg-icons' // ES Module "as" syntax
import { faCircleNotch as faFaCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { faCircleCheck as fasFaDone } from '@fortawesome/free-solid-svg-icons';
import { faCircle as fasFaNotStarted } from '@fortawesome/free-solid-svg-icons';
import SideMenu from "./SideMenuR"; // Import your SideMenu component
import HomeDV from './HomeDVR';
import DashboardDV from './DashboardDVR';
import AnalyticsDV from './AnalyticsDVR';
import DemographicDV from './DemographicDVR';

function DashboardMainR({ onLogout, token }) {
  const [selectedView, setSelectedView] = useState('Home');

    return (
      <div className="recycle-view-container">
      <SideMenu onLogout={onLogout} onSelectView={setSelectedView} />
        <div className="content">
        {selectedView === 'Home' && <HomeDV token={token} />}
          {selectedView === 'Dashboard' && <DashboardDV />}
          {selectedView === 'Analytics' && <AnalyticsDV />}
          {selectedView === 'Demographic' && <DemographicDV />}
        </div>
      </div>
    );
  }
  

export default DashboardMainR;