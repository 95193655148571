import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import React, { useState } from "react";
import "./App.css";
import { Navbar, Nav } from "react-bootstrap";

import HomeView from "./HomeView";
import ResearchLoginView from "./ResearchLoginView";
import CliniciansLoginView from "./CliniciansLoginView";
import DashboardMain from "./RDashboard/DashboardMainR";
import logoRoliner from "./images/roliner-logo.png";

function App() {
  const [currentView, setCurrentView] = useState("home");
  const [navExpanded, setNavExpanded] = useState(false);  // New state

  const handleMenuClick = (view) => {
    setCurrentView(view);
    setNavExpanded(false);  // Collapse the navbar
  };

  const handleLogout = () => {
    setCurrentView("clinics");
  };

  const handleShowDashboardMain = () => {
    setCurrentView("recycle");
  };

  return (
    <div className="App">
      {currentView !== "recycle" && (
        <Navbar
          className="top-bar"
          expand="md"
          expanded={navExpanded}  // Set the expanded state
          onToggle={(expanded) => setNavExpanded(expanded)}  // Update state on toggle
        >
        <Navbar.Brand>
          <img src={logoRoliner} alt="Logo" className="top-logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarNav" />
        <Navbar.Collapse id="navbarNav">
          <Nav>
            <Nav.Link
              className={`top-menu-item ${
                currentView === "home" ? "selected" : ""
              }`}
              onClick={() => handleMenuClick("home")}
            >
              Home
            </Nav.Link>
            <Nav.Link
              className={`top-menu-item ${
                currentView === "clinics" ? "selected" : ""
              }`}
              onClick={() => handleMenuClick("clinics")}
            >
              Clinics
            </Nav.Link>
            <Nav.Link
              className={`top-menu-item ${
                currentView === "research" ? "selected" : ""
              }`}
              onClick={() => handleMenuClick("research")}
            >
              Research
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      )}
      <div>
      {currentView === "home" && <HomeView />}
      {currentView === "clinics" && (
        <CliniciansLoginView onShowDashboardMain={handleShowDashboardMain} />
      )}
      {currentView === "research" && <ResearchLoginView />}
      {currentView === "recycle" && <DashboardMain onLogout={handleLogout} />}
      <div className="small-copyright-text">roliner © 2023</div>
    </div>
    </div>
  );
}

export default App;
