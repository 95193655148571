import React from 'react';
import './HomeView.css';
import imLogoUnhindr from "./images/im-1.png";
import imLogoRoliner from "./images/im-2.png";

function HomeView() {
  return (
    <div className="home-container">
      <div className="image-container">
        <img src={imLogoUnhindr} alt="Image 1" className="img-fluid mb-2"/>
        <img src={imLogoRoliner} alt="Image 2" className="img-fluid"/>
      </div>
    </div>
  );
}

export default HomeView;
