import React, { useState } from "react";
import "./SideMenuR.css"; // Importing the styles
import LogoutRoundedIcon from '@mui/icons-material/Logout';
import GroupsIcon from '@mui/icons-material/Groups';
import EqualizerRoundedIcon from '@mui/icons-material/EqualizerRounded';
import DataUsageRoundedIcon from '@mui/icons-material/DataUsageRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';

function SideMenuR({ onLogout, onSelectView }) { // Destructure onLogout from props here
    const [isMenuOpen, setMenuOpen] = useState(true);
    const [selectedItem, setSelectedItem] = useState('Home'); // Default selection is 'Dashboard'

    // Function to handle item selection
    const handleSelection = (itemName) => {
        setSelectedItem(itemName);
        if (onSelectView) onSelectView(itemName); // Call the new prop method
      };

    // Render each menu item with the correct className based on selected state
    const renderMenuItem = (itemName, IconComponent) => (
        <div 
            className={`menu-item ${selectedItem === itemName ? 'selected' : ''}`} 
            onClick={() => handleSelection(itemName)} // Set selected item on click
        >
            <IconComponent className="icon" />
            {isMenuOpen && <span className="text">{itemName}</span>}
        </div>
    );

  return (
        <div className={`side-menu ${isMenuOpen ? "open" : "closed"}`}>
            <div className="top-items">
                <div className="top-section">
                    {isMenuOpen && <div className="top-logo">roliner</div>}
                    <button className="toggle-button" onClick={() => setMenuOpen(!isMenuOpen)}>
                        {isMenuOpen ? <ChevronLeftRoundedIcon className="toggle-icon" /> : <ChevronRightRoundedIcon className="toggle-icon" />}
                    </button>
                </div>
                {renderMenuItem('Home', HomeRoundedIcon)}
                {renderMenuItem('Dashboard', EqualizerRoundedIcon)}
                {renderMenuItem('Analytics', DataUsageRoundedIcon)}
                {renderMenuItem('Demographic', GroupsIcon)}
                {/* ... Other items ... */}
            </div>
            <div className="logout-container">
  <div className="menu-item" onClick={onLogout}>
    <LogoutRoundedIcon className="icon" />
    {isMenuOpen && <span className="text">Logout</span>}
  </div>
</div>

        </div>
    );
}

export default SideMenuR;
