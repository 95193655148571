import React, { useEffect, useState } from 'react';
import { Line, Pie, Bar } from 'react-chartjs-2';
import './HomeDVC.css';


const HomeDVC = ({ token }) => {
  const [s3ObjectCount, setS3ObjectCount] = useState(null);
  const [userCount, setUserCount] = useState(null);
  const [userNames, setUserNames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [folders, setFolders] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    // Fetch S3 object data
    fetch('http://api.unhindr.io/api/count-s3-objects/', {
      headers: new Headers({
        'Authorization': `Bearer ${token}`,
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      setS3ObjectCount(data.count);
      setFolders(data.folders || []);
    })
    .catch(err => {
      setError(err);
    })
    .finally(() => {
      setIsLoading(false);
    });

    // Fetch Cognito user data
    fetch('http://api.unhindr.io/api/count-cognito-users/', {
      headers: new Headers({
        'Authorization': `Bearer ${token}`,
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`Cognito Fetch Error: ${response.statusText}`);
      }
      return response.json();
    })
    .then(data => {
      setUserCount(data.userCount);
      setUserNames(data.users || []);
    })
    .catch(err => {
      setError(err);
    });
  }, [token]);

  if (isLoading) {
    return <div className="dashboard">Loading...</div>;
  }

  if (error) {
    return <div className="dashboard">Error: {error.message}</div>;
  }

  return (
    <div className="dashboard">
      {folders.length > 0 && (
        <div>
          <h3>Folders in S3:</h3>
          <ul>
            {folders.map((folder, index) => (
              <li key={index}>{folder}</li>
            ))}
          </ul>
        </div>
      )}
      {s3ObjectCount !== null && <p>Count of objects in S3: {s3ObjectCount}</p>}
      {userCount !== null && (
        <div>
          <h3>User Count in Cognito: {userCount}</h3>
          <ul>
            {userNames.map((user, index) => (
              <li key={index}>{user.Username}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default HomeDVC;
