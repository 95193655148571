import React, { useState, useEffect } from 'react';
import "./ResearchLoginView.css";
import { TextField, InputAdornment } from '@material-ui/core';
import { AccountCircle, VisibilityOff } from '@material-ui/icons';
import RecycleView from './RDashboard/DashboardMainR';

const decodeJWT = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  const payload = JSON.parse(window.atob(base64));
  return payload;
};

function ResearchLoginView() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [token, setToken] = useState(null);  // New state for token

  useEffect(() => {
    if (token) {
      const decoded = decodeJWT(token);
      const expirationTime = decoded.exp * 1000; // convert to milliseconds
      const currentTime = new Date().getTime();
      const expiresAt = new Date(expirationTime);

      console.log(`Token will expire at: ${expiresAt.toLocaleString()}`);

      if (currentTime > expirationTime) {
        setToken(null);  // Remove token
        setIsLoggedIn(false);
        setError('Session has expired. Please log in again.');
      } else {
        setIsLoggedIn(true);
      }
    }
  }, [token]);

  const handleLogin = () => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8000/";
    const payload = {
      username: username,
      password: password,
    };

    fetch(`${API_BASE_URL}token/`, {
      method: "POST",
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        return response.json().then(data => {
          throw new Error(`${response.status}: ${data.detail || 'An error occurred'}`);
        });
      }
    })
    .then(data => {
      if (data.access) {
        setToken(data.access);  // Set token in state
        setIsLoggedIn(true);
      }
    })
    .catch(error => {
      console.error('Error details:', error);
      switch(error.message.split(":")[0]) {
        case '400':
          setError('Invalid input. Please check your data.');
          break;
        case '401':
          setError('Unauthorized. Invalid credentials.');
          break;
        default:
          setError(`An error occurred: ${error.message}`);
      }
    });
  };

  const handleLogout = () => {
    // API call to logout the user
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:8000/";
  
    fetch(`${API_BASE_URL}token/logout/`, {
      method: "DELETE", // Use DELETE method for logout
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}` // Assuming your API uses Bearer token authentication
      },
    })
    .then(response => {
      // Handle response from the server
      if (response.ok) {
        // Successful logout, handle it accordingly
        console.log("Logout successful");
      } else {
        // Handle logout failure
        if (response.status === 401) {
          // Unauthorized (token might have expired or is invalid)
          console.error("Unauthorized. Token might have expired.");
        } else if (response.status === 500) {
          // Internal Server Error (something went wrong on the server)
          console.error("Internal Server Error. Please try again later.");
        } else {
          // Handle other HTTP error statuses
          console.error(`Logout failed with status: ${response.status}`);
        }
      }
    })
    .catch(error => {
      // Handle network errors or other unexpected errors
      console.error("An error occurred during logout:", error);
    })
    .finally(() => {
      // Regardless of server response, clear client state
      setIsLoggedIn(false);
      setToken(null);
      setUsername('');
      setPassword('');
      // Also consider removing the token from storage if it's stored there
    });
  };
  
  
  if (isLoggedIn) {
    // Assuming RecycleView renders HomeDVR
    console.log("Token used for fetch:", token);

    return <RecycleView onLogout={handleLogout} token={token} />;
  }  

  const handleInputChange = (e) => {
    setError('');
    if (e.target.name === 'username') {
      setUsername(e.target.value);
    } else if (e.target.name === 'password') {
      setPassword(e.target.value);
    }
  };

  return (
    <div className="login-container-research container-fluid d-flex justify-content-top align-items-start vh-100">
      <div className="rectangle bg-white rounded p-4">
        {" "}
        {/* No need for column classes, custom CSS will handle width */}
        <h2 className="login-text text-center mb-5">Research Analytics Login</h2>
        <div className="mb-4">
          <TextField
            variant="outlined"
            label="Username"
            fullWidth
            name="username"
            value={username}
            onChange={handleInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="mb-4">
          <TextField
            variant="outlined"
            label="Password"
            fullWidth
            name="password"
            value={password}
            onChange={handleInputChange}
            type="password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <VisibilityOff />
                </InputAdornment>
              ),
            }}
          />
        </div>
        {error && <p className="error-message text-center mt-2">{error}</p>}
        <div className="login-button-container">
          <button
            className="login-button-research btn btn-primary"
            onClick={handleLogin}
          >
            Log in
          </button>
        </div>
      </div>
    </div>
  );
}

export default ResearchLoginView;
